import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  console.log(data)
  return (
    <Layout title={siteTitle} location={data.location}>
      <SEO title="BHS Instructor Devizes, Wiltshire" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            About Louise
          </h2>
          <p className="lead">I am an experienced and qualified freelance instructor and rider with a sympathetic approach to teaching, both people and horses.</p>
          <p>Based in Devizes, Wiltshire and surrounding areas, I am available for lessons from in-hand and lunging to flat and jump lessons. I also offer schooling, competing, and rehabilitation.</p>
          <p><strong>Coaching</strong></p>
          <p>I regularly coach a wide variety of people at different levels and ability, I like to focus on rider balance and position which I believe creates confident happy horses and riders. If you're intersted in booking a lesson give me a <a href="/contact">call</a> or send me a WhatsApp!</p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.teachImg.childImageSharp.fluid}
              className="kg-image"
            />
          </figure>
          <p><strong>Riding</strong></p>
          <p>I also regularly school a variety of horses and ponies at different levels and abilities. I really enjoy bringing them on and educating them. I believe this is done by trust, partnership and building a good foundation which hopefully means a happy hard working horse. If you're interested in me helping your horse with flat work, jumping, lunging or ground work give me a <a href="/contact">call</a> or send me a WhatsApp!</p>
          <p><strong>Competing</strong></p>
          <p>I have competed up to CIC 2 Star. Pepper has had to take a long break due to injury and me becoming a Mum. Hopefully I will be back out in 2023!</p>
          <h4>About my horses</h4>
          <p><strong>Pepper <small>(aka Snookoms)</small></strong></p>
          
          <p>Pepper is a Irish Draft with a little bit of everything else chucked in. Bought from a friend of a friend in 2015 who hoped to go hunting but she was quite right for her. She had very little experience although we knew she enjoyed jumping but she was very green and had a lot to learn.</p>
          <p>I bought her because we hoped she would be a sensible safe horse for me to ride and hopefully one I could take eventing and maybe one day do a BE 100.</p>
          <p>Since then we have gone from unaffiliated 70cm to 2*.</p>
          <p>What else can I say, She is amazing and we have a great partnership!</p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.aboutImg.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Snookoms, Dauntsey Horse Trials BE 100 2018, 8th Place</figcaption>
          </figure>
          <p><strong>Micky <small>(aka Pick and Mix II)</small></strong></p>
          
          <p>Micky is a legend and I was very lucky to have him. He was on loan from Emma Whitehead and I shared him with Elle Roberts once he retired from competing. He had a very long and successful career in juniors with some now top riders competing for Team GB and Ireland. He is responsible for helping me gain my confidence  and showing me the ropes eventing.</p>
          <p>I had two wonderful seasons on him, our best result (and still one of my best rounds) was 4th place at Dauntsey Horse Trials BE 100.</p>
          <p>Sadly Micky is no longer with us. I am forever grateful to him for helping me progress with my competing. I would not have managed 2* on Pepper without him.</p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.mixImg.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Pick and Mix II, Dauntsey Horse Trials BE 100 2017, 4th Place</figcaption>
          </figure>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutImg: file(
      relativePath: { eq: "about.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teachImg: file(
      relativePath: { eq: "teaching.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mixImg: file(
      relativePath: { eq: "mix.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
